<template>
  <Toast />
  <div class="p-grid crud-demo">
    <div class="p-col-12 p-md-8">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb-2"
              v-tooltip.top="'Novo Registro.'"
              @click="openEquip"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="equipamentos"
          class="p-datatable-sm"
          v-model:selection="selectedEquipamento"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filtersFuncao"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} funções"
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Equipamentos</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filtersEquip['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column field="ip" header="IP" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">IP</span>
              {{ slotProps.data.ip }}
            </template>
          </Column>
          <Column field="porta" header="Porta">
            <template #body="slotProps">
              <span class="p-column-title">Porta</span>
              {{ slotProps.data.porta }}
            </template>
          </Column>
          <Column field="gateway" header="Gateway">
            <template #body="slotProps">
              <span class="p-column-title">Gateway</span>
              {{ slotProps.data.gateway }}
            </template>
          </Column>
          <Column field="mascara" header="Sub-Rede">
            <template #body="slotProps">
              <span class="p-column-title">Sub-Rede</span>
              {{ slotProps.data.mascara }}
            </template>
          </Column>
          <Column field="tipoEquipamento" header="Tipo Equipamento">
            <template #body="slotProps">
              <span class="p-column-title">Tipo Equipamento</span>
              {{ slotProps.data.tipoEquipamento.nome }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                v-tooltip.top="'Alterar.'"
                @click="openEditEquip(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning p-mr-2"
                v-tooltip.top="'Excluir.'"
                @click="openDeleteFuncao(slotProps.data)"
              />
              <Button
                icon="pi pi-send"
                class="p-button-rounded"
                v-tooltip.top="'Acessar equipamento.'"
                @click="openExternal(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- CADASTRAR E ATUALIZAR FUNÇÕES-->
        <Dialog
          v-model:visible="equipamentoDialog"
          :style="{ width: '480px' }"
          :header="titleDialog"
          :modal="true"
          @hide="hideDialog"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="ident">Identificador</label>
            <InputText
              id="ident"
              v-model.trim="equipamento.identificador"
              required="true"
              placeholder="Identificador do equipamento."
              autofocus
            />
          </div>
          <div class="p-field">
            <label for="descricao">Descricao</label>
            <InputText
              id="descricao"
              v-model.trim="equipamento.descricao"
              required="true"
              placeholder="Descrição do equipamento."
            />
          </div>
          <div class="p-field">
            <label for="tipoEquip">Tipo de Equipamento</label>
            <Dropdown
              id="tipoEquip"
              :options="tipoEquipamentos"
              optionLabel="nome"
              optionValue="id"
              v-model="equipamento.tipoEquipamento.id"
              required="true"
              placeholder="Selecione o tipo."
            />
          </div>
          <div class="p-field">
            <label for="ip">Ip</label>
            <InputText
              id="ip"
              v-model.trim="equipamento.ip"
              placeholder="Informe o ip."
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="port">Porta</label>
            <InputText
              id="port"
              v-model.trim="equipamento.porta"
              required="true"
              placeholder="Informe a porta."
            />
          </div>
          <div class="p-field">
            <label for="gt">Gateway</label>
            <InputText
              id="gt"
              v-model.trim="equipamento.gateway"
              placeholder="Informe o gateway."
              required="true"
            />
          </div>
          <div class="p-field">
            <label for="mask">Máscara de Sub-Rede</label>
            <InputText
              id="mask"
              v-model.trim="equipamento.mascara"
              placeholder="Informe a máscara de sub-rede."
              required="true"
            />
          </div>

          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendEquip"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM CADASTRAR E ATUALIZAR FUNÇÕES-->

        <!-- EXCLUIR FUNÇÕES-->
        <Dialog
          v-model:visible="deleteEquipamentoDialog"
          :style="{ width: '450px' }"
          :modal="true"
          @hide="hideDialog"
          header="Mensagem de Confirmação!"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="equipamento"
              >Tem certeza de que deseja excluir o registro
              <b>{{ equipamento.descricao }} ?</b></span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteEquip"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM EXCLUIR FUNÇÕES-->
      </div>
    </div>

    <div class="p-col-12 p-md-4">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb-2"
              v-tooltip.top="'Novo Registro.'"
              @click="openTpEquip"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="tipoEquipamentos"
          class="p-datatable-sm"
          v-model:selection="selectedTipoEquipamento"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filtersCargo"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 15, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} funções"
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Tipo de Equipamento</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filtersTipoEquip['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column field="nome" header="Tipo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Tipo</span>
              {{ slotProps.data.nome }}
            </template>
          </Column>
          <Column field="descricao" header="Descrição" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Descrição</span>
              <div v-if="!slotProps.data.descricao">Sem Descrição</div>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                v-tooltip.top="'Alterar'"
                @click="openEditTpEquip(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                v-tooltip.top="'Excluir'"
                @click="openDeleteTpEquip(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <!-- CADASTRAR E ATUALIZAR CARGO-->
        <Dialog
          v-model:visible="tipoEquipamentoDialog"
          :style="{ width: '480px' }"
          :header="titleDialog"
          :modal="true"
          @hide="hideDialog"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="tp">Tipo</label>
            <InputText
              id="tp"
              v-model.trim="tipoEquipamento.nome"
              required="true"
              placeholder="Informe o tipo."
              autofocus
            />
          </div>
          <div class="p-field">
            <label for="desc">Descrição</label>
            <InputText
              id="desc"
              v-model.trim="tipoEquipamento.descricao"
              required="true"
              placeholder="Informe a descrição."
              autofocus
            />
          </div>
          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendTpEquip"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM CADASTRAR E ATUALIZAR CARGO-->

        <!-- EXCLUIR CARGO-->
        <Dialog
          v-model:visible="deletetipoEquipamentoDialog"
          :style="{ width: '450px' }"
          :modal="true"
          @hide="hideDialog"
          header="Mensagem de Confirmação!"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="tipoEquipamento"
              >Tem certeza de que deseja excluir o registro
              <b>{{ tipoEquipamento.nome }} ?</b></span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteTpEquip"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM EXCLUIR CARGO-->
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "@/service/Auth/AuthService";
import EquipamentoService from "@/service/Equipamentos/EquipamentoService";
import TipoEquipamentoService from "@/service/Equipamentos/TipoEquipamentoService";

export default {
  data() {
    return {
      /**INICIO VARIAVEIS FUNCOES */
      equipamentos: null,
      equipamentoDialog: false,
      deleteEquipamentoDialog: false,
      selectedEquipamento: null,
      equipamento: {
        id: null,
        ip: null,
        porta: null,
        gateway: null,
        mascara: null,
        identificador: null,
        descricao: null,
        tipoEquipamento: {
          id: null,
          nome: null,
          descricao: null,
        },
      },
      tipoEquipamentos: null,
      tipoEquipamentoDialog: false,
      deletetipoEquipamentoDialog: false,
      selectedTipoEquipamento: null,
      tipoEquipamento: {
        id: null,
        nome: null,
        descricao: null,
      },
      titleDialog: null,
      filtersEquip: {},
      filtersTipoEquip: {},
      submitted: false,
      msg: null,
      msgUsuario: null,
      msgDesenvolvedor: null,
    };
  },
  tipoEquipamentoService: null,
  equipamentoService: null,
  authService: null,
  created() {
    this.authService = new AuthService();
    this.equipamentoService = new EquipamentoService();
    this.tipoEquipamentoService = new TipoEquipamentoService();
  },
  mounted() {
    this.authService.checarToken(this.listEquipamento());
    this.authService.checarToken(this.listTpEquip());
  },
  methods: {
    hideDialog() {
      this.equipamentoDialog = false;
      this.tipoEquipamentoDialog = false;
      this.deletetipoEquipamentoDialog = false;
      this.submitted = false;
      this.equipamento = {
        id: null,
        ip: null,
        porta: null,
        gateway: null,
        mascara: null,
        identificador: null,
        descricao: null,
        tipoEquipamento: {
          id: null,
          nome: null,
          descricao: null,
        },
      };
      this.tipoEquipamento = {
        id: null,
        nome: null,
        descricao: null,
      };
      this.tipoEquipamento = {};
      this.msgUsuario = null;
      this.msgDesenvolvedor = null;
    },
    openEquip() {
      this.titleDialog = "Nova Equipamento";
      this.equipamentoDialog = true;
    },
    openEditEquip(equip) {
      this.equipamento = equip;
      this.titleDialog = this.equipamento.ip;
      this.equipamentoDialog = true;
    },
    openDeleteFuncao(equip) {
      this.equipamento = equip;
      this.deleteEquipamentoDialog = true;
    },
    listEquipamento() {
      this.equipamentoService.listar().then((data) => {
        this.equipamentos = data;
      });
    },
    saveEquip() {
      this.submitted = true;
      if (this.equipamento.id == null) {
        this.equipamentoService
          .criar(this.equipamento)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Equipamento salva com sucesso.",
                life: 3000,
              });
              this.listEquipamento();
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;
          });
      } else {
        this.equipamentoService
          .atualizar(this.equipamento.id, this.equipamento)
          .then((data) => {
            if (data.status === 200) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Equipamento atualizado com sucesso.",
                life: 3000,
              });
              this.listEquipamento();
              this.hideDialog();
            }
          });
      }
    },
    deletarEquip() {
      this.equipamentoService.remover(this.equipamento.id).then((data) => {
        if (data.status === 204) {
          this.deleteEquipamentoDialog = false;
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Equipamento deletada com sucesso.",
            life: 3000,
          });
          this.listEquipamento();
        } else {
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao deletar registro.",
            life: 3000,
          });
        }
      });
    },
    sendEquip() {
      this.authService.checarToken(this.saveEquip());
    },
    deleteEquip() {
      this.authService.checarToken(this.deletarEquip());
    },
    openTpEquip() {
      this.titleDialog = "Tipo de Equipamento";
      this.tipoEquipamentoDialog = true;
    },
    openEditTpEquip(tp) {
      this.tipoEquipamento = tp;
      this.titleDialog = this.tipoEquipamento.nome;
      this.tipoEquipamentoDialog = true;
    },
    openDeleteTpEquip(tp) {
      this.tipoEquipamento = tp;
      this.deletetipoEquipamentoDialog = true;
    },
    listTpEquip() {
      this.tipoEquipamentoService.listar().then((data) => {
        this.tipoEquipamentos = data;
      });
    },
    saveTpEquip() {
      this.submitted = true;
      if (this.tipoEquipamento.id == null) {
        this.tipoEquipamentoService
          .criar(this.tipoEquipamento)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Tipo salvo com sucesso.",
                life: 3000,
              });
              this.listTpEquip();
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.reqError = true;
            this.cargo.descricao = null;
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;
          });
      } else {
        this.tipoEquipamentoService
          .atualizar(this.tipoEquipamento.id, this.tipoEquipamento)
          .then((data) => {
            if (data.status === 200) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Tipo atualizado com sucesso.",
                life: 3000,
              });
              this.listTpEquip();
              this.hideDialog();
            }
          });
      }
    },
    deletarTpEquip() {
      this.tipoEquipamentoService
        .remover(this.tipoEquipamento.id)
        .then((data) => {
          if (data.status === 204) {
            this.listTpEquip();
            this.deletetipoEquipamentoDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sucesso!",
              detail: "Tipo deletado com sucesso.",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "erro",
              summary: "Erro!",
              detail: "Erro ao deletar registro.",
              life: 3000,
            });
          }
        });
    },
    sendTpEquip() {
      this.authService.checarToken(this.saveTpEquip());
    },
    deleteTpEquip() {
      this.authService.checarToken(this.deletarTpEquip());
    },
    openExternal(external) {
      window.open("http://" + external.ip + "", "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setor-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-dialog .setor-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setor-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>